import React, {useState, useEffect, useContext} from "react";
import {useLocation, useParams} from "react-router-dom";
import {getLanguage, useTranslation} from "react-multi-lang/lib";
import PollFrame from "../../Polls/Modules/PollFrame";
import {SurveyStatus, SurveyTypes} from "../../Polls/Modules/Types";
import {Button, Col, Row} from "react-bootstrap";
import _ from "lodash";
import {FullContrastBlueTextField, uploadFile, TourType, Roles} from "../../../../../../Utils/Global";
import EbloomTimePicker from "../../../../../Core/Modules/Input/EbloomTimePicker";
import moment from "moment";
import WeBloomTeamPicker from "../../Modules/WeBloomTeamPicker";
import ConfirmModal from "../../../../../Core/Modules/Modal/ConfirmModal";
import {useSavePage} from "../../../../../../hooks/savePageHook";
import {ReactComponent as MessageIcon} from "../../../../../Core/symbols/webloom/white/message-white.svg";
import {useNavigationTo} from "../../../../../../hooks/navigationHook";
import HttpApi from "../../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import AnswerMessage from "../../../../../Employee/Pages/WeBloom/Message/Answer/Modules/AnswerMessage";
import {useUserData} from "../../../../../../hooks/userHook";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import FileInput from "../../../../../Core/Modules/Input/FileInput";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import {ProductTourContext} from "../../../../../../Utils/Context/ProductTourContext";
import EbloomSelectLang from "../../../../../Core/Modules/Input/EbloomSelectLang";
import FreeTrialModal from "../../../../../Core/Modules/Modal/FreeTrialModal";
import Checkbox from "@mui/material/Checkbox";
import WeBloomEntitiesPicker from "../../Modules/WeBloomEntitiesPicker";
import { InputAdornment } from '@mui/material';
import EbloomInfoTooltip from '../../../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip';
import { Colors } from '../../../../../../Utils/Colors';
import WebloomDatePicker from "../../../../../Core/Modules/Input/WebloomDatePicker";

const WeBloomCreateMessage = (props) => {

    //state
    const [isPreview, setIsPreview] = useState(false);
    const [showModalReset, setShowModalReset] = useState(false);
    const [showModalSend, setShowModalSend] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingAttachment, setLoadingAttachment] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);

    const [id, setId] = useState(null);
    const [title, setTitle] = useState(null);
    const [lang, setLang] = useState(getLanguage());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startHour, setStartHour] = useState("select");
    const [status, setStatus] = useState(SurveyStatus.DRAFT);
    const [team, setTeam] = useState(-1);
    const [targets, setTargets] = useState([]);
    const [recipients, setRecipients] = useState("allCurrentAndFutureUsers");
    const [question, setQuestion] = useState({id:null, title:"", type:SurveyTypes.MESSAGE});
    const [attachmentUrl, setAttachmentUrl] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [hasEndedFreeTrial, setHasEndedFreeTrial] = useState(false);
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);

    const [hasModifiedRecipients, setHasModifiedRecipients] = useState(false);
    const [sendNotification, setSendNotification] = useState(false);

    const [isGroup, setIsGroup] = useState(false);

    //hooks
    const t = useTranslation();
    const location = useLocation();
    const navigation = useNavigationTo();
    const page = useSavePage(true);
    const user = useUserData();
    const params = useParams()


    //context
    const {validateOutcome,tourActivated} = useContext(ProductTourContext)
    const isProspect = user.module === "prospect"

    const isDraft =  null !== id;


    //getters
    const reloadSurvey = (id) => {
        let url = isGroup ? "/webloom/getSurvey/"+ id + '/' + user.companyGroup.id : "/webloom/getSurvey/" + id
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setId(response.data.id);
                initiateSurvey(response.data);
            }else{
                navigation.forceTo(isGroup ? "/group/webloom/dashboard" : "/admin/webloom/dashboard");
            }
        })
    }

    const fetchEndDate = () => {
        if(user.module === "free"){
            HttpApi.getV2("/companies/getEndDate").then(response => {
                if(response && response.data){
                    setHasEndedFreeTrial(moment(response.data.end_date).isSameOrBefore(moment()));
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    //posters
    const saveSurvey = (newStatus) => {
        validateSurvey(newStatus === SurveyStatus.DRAFT).then(() => {
            const data = isDraft ? {
                id: id,
                title:title,
                lang:lang,
                questions: [question],
                status: newStatus,
                users: team === -1 ? "all" : targets,
                recipients: recipients,
                start_date: startDate,
                end_date: endDate,
                type:SurveyTypes.MESSAGE,
                attachment_url:attachmentUrl,
                logo_url: logoUrl,
                image_url:imageUrl,
                id_company : isGroup ? user.companyGroup.id : user.companyId,
                send_notification:status !== SurveyStatus.RUNNING ? true : sendNotification

            } : {
                title:title,
                lang:lang,
                questions: [question],
                status: newStatus,
                users: team === -1 ? "all" : targets,
                recipients: recipients,
                start_date: startDate,
                end_date: endDate,
                type:SurveyTypes.MESSAGE,
                attachment_url:attachmentUrl,
                image_url:imageUrl,
                logo_url: logoUrl,
                id_company : isGroup ? user.companyGroup.id : user.companyId,
                send_notification:status !== SurveyStatus.RUNNING ? true : sendNotification

            };
            let url = isDraft ? "/webloom/update" : "/webloom/create"
            const HttpCall = isDraft ? HttpApi.put : HttpApi.postV2;
            if(isGroup){
                url += '/' + data.id_company;
            }
            HttpCall(url, data).then((response) => {
                page.save();

                if (newStatus !== SurveyStatus.DRAFT) {
                    if(tourActivated){
                        validateOutcome(TourType.LAUNCH_WEBLOOM)
                    }
                    notifySuccess(_.upperFirst(t((startDate ? "webloom.notification.surveyPlaned": "webloom.notification.surveySent"), {survey:t("webloom.message")})))
                    navigation.forceTo(isGroup ? "/group/webloom/dashboard" : "/admin/webloom");
                }else{
                    notifySuccess(_.upperFirst(t("webloom.notification.surveySaved", {survey:t("webloom.message")})));
                    const id_survey = id || response.data;
                    reloadSurvey(id_survey);
                }
            });
        }).catch(error => {
            createErrorNotification(_.upperFirst(t("core.notification.error")), _.upperFirst(t(error)));
        })
    }

    //handlers
    const validateSurvey = (saveDraft) => {
        return new Promise((resolve, reject) => {
            if(!question || !question.title){
                reject("webloom.notification.messageError");
            }
            if(status !== SurveyStatus.RUNNING && startDate){
                if(moment(startDate).isSameOrBefore(moment())){
                    reject(_.upperFirst(t("webloom.notification.futurePollError")));
                }else if(moment(startDate).get("hour") < 8 || moment(startDate).get("hour") > 18 || moment(startDate).get("minute") % 5 !== 0){
                    reject(_.upperFirst(t("webloom.notification.startHourError")));
                }
            }
            if(team !== -1 && targets.length < 1 && !saveDraft){
                reject(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom.message")})))
            }
            if(team !== -1 && user.companyId === 177 && targets.length < 20 && !saveDraft){//GSK limite à 20
                reject(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom.message")})))
            }
            resolve(true);
        })
    }
    const handleOpenResetModal = () => {
        setShowModalReset(true);
    }

    const handleCloseModalReset = () => {
        setShowModalReset(false);
    }

    const handleOpenModalSend = () => {
        validateSurvey(false).then(() => {
            setShowModalSend(true);
        }).catch(error => {
            createErrorNotification(_.upperFirst(t("core.notification.error")), _.upperFirst(t(error)));
        })

    }

    const handleCloseModalSend = () => {
        setShowModalSend(false);
    }

    const handleReset = () => {
        setTitle(null);
        setEndDate(null);
        setStartDate(null);
        setRecipients("allCurrentAndFutureUsers");
        setTargets([]);
        setTeam(-1);
        setAttachmentUrl(null);
        setImageUrl(null);
        setQuestion({id:null, title:"", type:SurveyTypes.MESSAGE})
        setLang(getLanguage());
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeTeam = (team, targets, recipients, staySaved=false) => {
        if(team !== null){
            setTeam(team);
        }
        if(targets !== null){
            setTargets(targets);
        }
        if(recipients !== null){
            setRecipients(recipients);
        }
        if(page.isSaved && !staySaved){
            page.unSave();
        }
        if(SurveyStatus.RUNNING === status && hasModifiedRecipients === false){
            setHasModifiedRecipients(true);
        }
    }

    const handleChangeMessage = (event) => {
        setQuestion(prevState => {
            let question = {...prevState};
            question.title = event.target.value;
            return question;
        })
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeStartDate = (date) => {
        //Change Hour Automatically to 12:00 if it's first time planning a start date
        if(!!date){
            if(!startDate){
                //Set new start date with new hour setting to 12:00
                let sd = moment(date).set("hour", 12).set("minute", 0).format("YYYY-MM-DD HH:mm")
                setStartDate(new Date(sd));
            }else{
                //Set new start date to previous hour setting
                const datetime = moment(startDate).format("HH:mm");
                let [hour, minutes] = datetime.split(":");
                let sd = moment(date).set("hour", parseInt(hour)).set("minute", parseInt(minutes)).format("YYYY-MM-DD HH:mm")
                setStartDate(new Date(sd));
            }
        }else{
            setStartDate(date);
        }

        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeEndDate = (date) => {
        let sd = moment(date).endOf("day").format("YYYY-MM-DD HH:mm")
        setEndDate(new Date(sd));
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeTime = (event) => {
        let [hour, minutes] = event.target.value.split(":");
        let sd = moment(startDate).set("hour", parseInt(hour)).set("minute", parseInt(minutes)).format("YYYY-MM-DD HH:mm")
        setStartDate(new Date(sd));
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleLogo = (event) => {
        const file = event.target.files[0];
        if(file && !loadingLogo){
            setLoadingLogo(true);
            uploadFile(file).then((url) => {
                setLogoUrl(url)
                setLoadingLogo(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setLogoUrl(null);
                setLoadingLogo(false);
                notifyError(error);
            })
        }else{
            setLogoUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleImage = (event) => {
        const file = event.target.files[0];
        if(file && !loadingImage){
            setLoadingImage(true);
            uploadFile(file).then((url) => {
                setImageUrl(url);
                setLoadingImage(false);
                notifySuccess("Image uploaded");
            }).catch(error => {
                setImageUrl(null);
                notifyError(error);
            })
        }else{
           setImageUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleFile = (event) => {
        const file = event.target.files[0];
        if(file && !loadingAttachment){
            setLoadingAttachment(true);
            uploadFile(file).then((url) => {
                setAttachmentUrl(url);
                setLoadingAttachment(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setAttachmentUrl(null);
                notifyError(error);
            })
        }else{
            setAttachmentUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleDeleteImage = () => {
        setImageUrl(null);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleDeleteAttachment = () => {
        setAttachmentUrl(null);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleLang = (event) => {
        setLang(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const displayRecipients = () => {
        switch (team){
            case -1:
                return _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"));
            case -3:
                return _.upperFirst(t("core.collaborative.allCurrentUsers"));
            case -2:
                return _.upperFirst(t("core.customized"));
            case -4:
                return null;
            default:
                return recipients
        }
    }

    const initiateSurvey = (survey) => {
        if(survey.title){
            setTitle(survey.title);
        }
        if (survey.status !== SurveyStatus.RUNNING && survey.start_date && moment(survey.start_date).isBefore(moment())) {
            setStartDate(null);
        } else {
            setStartDate(survey.start_date ? new Date(survey.start_date) : null);
        }
        if (survey.end_date && moment(survey.end_date).isSameOrBefore(moment())) {
            setEndDate(null);
        } else {
            setEndDate(survey.end_date ? new Date(survey.end_date) : null);
        }
        if (survey.recipients !== "allCurrentAndFutureUsers") {
            setTargets(survey.users);
        }
        setLang(survey.lang);
        setTeam(survey.recipients === "allCurrentAndFutureUsers" ? -1 : survey.recipients === "allCurrentUsers" ? -3 : survey.recipients === "customized" ? -2 : survey.recipients);
        setRecipients(survey.recipients);
        setQuestion({id: survey.question.id, title: survey.question.text, type: SurveyTypes.MESSAGE, imageUrl: survey.question.image_url});
        setAttachmentUrl(survey.attachment_url);
        setImageUrl(survey.image_url);
        setStatus(survey.status);
        setLogoUrl(survey.logo_url);
    }

    //useEffect

    useEffect(() => {
        fetchEndDate();
        setIsGroup(!!params.idGroup)
        if(location && location.state) {
            let survey = location.state;
            const url = new URL(window.location.href);
            const duplicate = url.searchParams.get("duplicate");
            if (!duplicate) {
                setId(survey.id);
            }else{
                if(SurveyStatus.RUNNING === survey.status || SurveyStatus.DONE === survey.status){
                    survey.status = SurveyStatus.DRAFT;
                }
                page.unSave();
            }
            initiateSurvey(survey);
        }
    }, [params])

    useEffect(() => {
        if(isProspect){
            page.save()
        }
    }, [page]);


    return (
        <>
            <PollFrame hideReset={status === SurveyStatus.RUNNING} type={SurveyTypes.MESSAGE} isPreview={isPreview} handlePreview={() => {setIsPreview(!isPreview)}} handleReset={handleOpenResetModal} status={SurveyStatus.DRAFT} isSaved={page.isSaved} isGroup={isGroup} >
                {
                    isPreview ?
                        <AnswerMessage survey={{title:title, question:question, start_date:startDate, end_date:endDate, start_hour:startHour, attachment_url:attachmentUrl, image_url:imageUrl}} />
                        :
                        <div>
                            <Row>
                                <Col md={4} className={"flex items-center justify-center"}>
                                    <MessageIcon className={"h-90 w-90 contrast-blue-icon"}/>
                                </Col>
                                <Col md={6}>
                                    <Row className={"items-center mt-10 mb-10"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.title"))}</span>
                                        </Col>
                                        <Col>
                                            <FullContrastBlueTextField variant={"outlined"} placeholder={_.upperFirst(t("core.title"))} onChange={handleChangeTitle} value={title} size="small" multiline InputProps={{endAdornment:<InputAdornment position={"end"}><EbloomInfoTooltip colors={{secondary:Colors.EBLOOM_CONTRAST_PURPLE}} size='sm' text={_.upperFirst(t("webloom.titleHelper"))}/></InputAdornment>}}/>
                                        </Col>
                                    </Row>
                                    {
                                        Roles.BACKOFFICE.some( el =>  user.roles.includes(el))  &&
                                        <Row className={"items-center mt10 mb10"}>
                                            <Col md={3}>
                                                <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.login.lang"))}</span>
                                            </Col>
                                            <Col>
                                                <EbloomSelectLang handleChange={handleLang} value={lang} color="blue"/>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                      (user.roles.includes("global_backoffice")) &&
                                      <Row>
                                          <Col md={3}>
                                              <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("logo partenaire"))}</span>
                                          </Col>
                                          <Col className={"flex items-center"}>
                                              <FileInput className={"mr-5"} handleChange={handleLogo} showText={!logoUrl && !loadingLogo} iconColor={"contrast-blue"}/>
                                              {
                                                  loadingLogo ?
                                                    <LoadingView size={16}/>
                                                    :
                                                    logoUrl &&
                                                    <FileDisplay urlParams={logoUrl} handleDelete={() => {setLogoUrl(null)}} showIcon={false}/>
                                              }
                                          </Col>
                                      </Row>
                                    }
                                    <Row className={"items-start mt10 mb10"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.dates"))}</span>
                                        </Col>
                                        <Col className={"flex justify-between items-center "}>
                                            <Row className={"w-full flex justify-between items-center "}>
                                                <div className={"w-full flex justify-between items-center "} >
                                                     <span
                                                         className={"text-contrast-blue ebloom-text ebloom-small-text mr-5"}>{_.upperFirst(t("core.start"))}</span>
                                                    <div className={"w-3/4"}>
                                                        {
                                                            status === SurveyStatus.RUNNING ?
                                                                <div
                                                                    className={"text-contrast-blue ebloom-text ebloom-small-text"}>{moment(startDate).format("DD/MM/YY HH:mm")}</div>
                                                                :
                                                                <div>
                                                                    <WebloomDatePicker date={startDate}
                                                                                       handleDate={handleChangeStartDate}/>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    startDate &&
                                                    <div
                                                        className={"w-full ebloom-link-blue text-center"}
                                                        onClick={() => {
                                                            handleChangeStartDate(null)
                                                        }}>{_.upperFirst(t("core.now"))}</div>
                                                }
                                            </Row>

                                        </Col>
                                        <Col className={"flex justify-between items-center flex-row"}>
                                            <Row className={"w-full flex justify-between items-center "}>
                                                <div className={"w-full flex justify-between items-center "}>
                                                    <span
                                                        className={"text-contrast-blue ebloom-text ebloom-small-text mr-5"}>{_.upperFirst(t("core.end"))}</span>
                                                    <div className={"w-3/4"}>
                                                        <div>
                                                            <WebloomDatePicker date={endDate}
                                                                               handleDate={handleChangeEndDate} fromDate={startDate}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    endDate &&
                                                    <div className={"w-full text-center ebloom-link-blue"}
                                                         onClick={() => {
                                                             handleChangeEndDate(null)
                                                         }}>{_.upperFirst(t("core.never"))}</div>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        (null !== startDate && status !== SurveyStatus.RUNNING) &&
                                        <Row className={"items-center my-10"}>
                                            <Col md={3}>
                                                <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.hour"))}</span>
                                            </Col>
                                            <Col>
                                                <EbloomTimePicker handleChange={handleChangeTime} selectedHour={(moment(startDate).isBefore(moment()) || moment(startDate).get("hour") < 8 || moment(startDate).get("hour") > 18 || moment(startDate).get("minute") % 5 !== 0) ? "select" : moment(startDate).format("HH:mm").toString()} isToday={moment(startDate).format("DD/MM/YY") === moment().format("DD/MM/YY")}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"items-center my-10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.collaborative.recipients"))}
                                                {
                                                    targets && targets.length > 0 &&
                                                    <span className={"ml-5"}>{"("+targets.length+")"}</span>
                                                }
                                            </div>
                                        </Col>
                                        <Col>
                                            {
                                                isGroup ?
                                                    <WeBloomEntitiesPicker handleChange={handleChangeTeam} team={team} targets={targets} />
                                                    :
                                                    <WeBloomTeamPicker isDraft={isDraft} isProspect={isProspect} handleChange={handleChangeTeam} team={team} targets={targets}/>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr className={"border-contrast-blue my-20"}/>
                            <div className={"w-1/2 m-auto"}>
                                {
                                    imageUrl &&
                                    <FileDisplay className={"m-auto"} urlParams={imageUrl} displayImg handleDelete={handleDeleteImage}/>
                                }
                                <FullContrastBlueTextField variant={"outlined"} multiline rows={5} placeholder={_.upperFirst(t("webloom.message"))} fullWidth value={question.title} onChange={handleChangeMessage}/>
                                <div className={"flex items-center mt-10 " + (attachmentUrl ? "justify-between" : "justify-end")}>
                                    {
                                        attachmentUrl &&
                                        <FileDisplay urlParams={attachmentUrl} handleDelete={handleDeleteAttachment}/>
                                    }
                                    <div className={"flex items-center"}>
                                        {
                                            loadingImage ?
                                                <LoadingView size={16} />
                                                :
                                                <FileInput className={"mx-5"} handleChange={handleImage} iconColor={"contrast-blue"} type={"image"}/>
                                        }
                                        {
                                            loadingAttachment ?
                                                <LoadingView size={16}/>
                                                :
                                                <FileInput className={"mx-5"} handleChange={handleFile} iconColor={"contrast-blue"}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                {
                    (question && question.title && !isProspect) &&
                    <div className={"flex items-center justify-end mt-20 mr-15"}>
                        {
                            (!page.isSaved && SurveyStatus.RUNNING !== status) &&
                            <span className={"ebloom-link-purple"} onClick={() => {saveSurvey(status)}}>{status === SurveyStatus.RUNNING ? _.upperFirst(t("core.save")) : _.upperFirst(t("core.saveParam", {param:(Roles.BACKOFFICE.some( el =>  user.roles.includes(el))  ? "template" : t("core.collaborative.draft"))}))}</span>
                        }
                        {
                            (!Roles.BACKOFFICE.some( el =>  user.roles.includes(el)) ) &&
                            <Button size={'sm'} disabled={question.title.length === 0} className={"ebloom-btn-purple ml-10"} onClick={hasEndedFreeTrial ? () => setShowFreeTrialModal(true) : handleOpenModalSend}>{(startDate && status !== SurveyStatus.RUNNING) ? _.upperFirst(t("core.collaborative.schedule")) : (startDate && status === SurveyStatus.RUNNING) ? _.upperFirst(t("core.save")) : _.upperFirst(t("core.send"))}</Button>
                        }
                    </div>
                }
            </PollFrame>
            <ConfirmModal handleClose={handleCloseModalReset} show={showModalReset} title={_.upperFirst(t("core.confirmation")) +" - " + _.upperFirst(t("webloom.resetParam",{param:t("webloom.message")}))} handleChange={handleReset} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                <p>{_.upperFirst(t("webloom.resetPollModal", {survey:t("webloom.theMessage")}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalSend} show={showModalSend} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t(SurveyStatus.RUNNING === status ? "core.updateParam" :"core.sendParam", {param:t("webloom.message")}))} handleChange={() => {saveSurvey((startDate && status !== SurveyStatus.RUNNING) ? SurveyStatus.SCHEDULED : SurveyStatus.RUNNING)}} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                {
                    SurveyStatus.RUNNING !== status ?
                    <p><span>{startDate ?
                        _.upperFirst(t("webloom.sendPollModal", {recipients:displayRecipients(), startDate:moment(startDate).format("DD/MM/YY"), startHour:moment(startDate).format("HH:mm"), survey:t("webloom.theMessage")}))
                        :
                        _.upperFirst(t("webloom.sendPollModalNoStart", {recipients:displayRecipients(), survey:t("webloom.theMessage")}))
                    }</span> <span>
                    {
                        endDate ?
                            _.upperFirst(t("webloom.sendPollModalEnd", {endDate:moment(endDate).format("DD/MM/YY")}))
                            : _.upperFirst(t("webloom.sendPollModalNoEnd"))
                    }
                </span>
                    </p>
                        :
                        <div>
                            <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdated", {survey:t("webloom.thePoll")}))}</p>
                            <div className={"my-10 flex items-top justify-center"}>
                                <Checkbox className={"checkbox-purple p-0"} checked={sendNotification} onChange={() => {setSendNotification(!sendNotification)}}/>
                                <div className={"ml-5"}>{_.upperFirst(t(hasModifiedRecipients ? "webloom.sendPollModalUpdatedModifiedRecipientsCheckbox" :"webloom.sendPollModalUpdatedCheckbox", {recipients:displayRecipients()}))}</div>
                            </div>
                        </div>
                }
            </ConfirmModal>
            <FreeTrialModal handleClose={() => setShowFreeTrialModal(false)} show={showFreeTrialModal} text={""}/>
        </>
    )
}

WeBloomCreateMessage.propTypes = {}

WeBloomCreateMessage.defaultProps = {}
export default WeBloomCreateMessage