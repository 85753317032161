import React, { useEffect, useState} from "react";
import {Badge, Card, Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import HttpApi, {Sources} from "../../../httpApi";
import Login from "../../../login/login"
import {notifyError, notifySuccess} from "../../../Utils/Notification";
import moment from "moment";
import _ from "lodash";
import {Colors} from "../../../Utils/Colors";
import SimpleRadarChart from "../../Core/Modules/Chart/SimpleRadarChart";
import LoadingView from "../../Core/Layouts/LoadingView";
import History from "../Modules/History";
import {useTranslation} from "react-multi-lang/lib";
import SimpleBubbleChart from "../../Core/Modules/Chart/SimpleBubbleChart";
import DashboardCard from "../../Core/Modules/Cards/DashboardCard";
import * as fileSaver from "file-saver";
import ConfirmModal from "../../Core/Modules/Modal/ConfirmModal";
import {getLanguage} from "react-multi-lang";
import CategoryEvolutionLayout from "../../Core/Layouts/CategoryEvolutionLayout";
import ExportIcon from '@mui/icons-material/Launch';
import EbloomTooltip from "../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import EbloomSmileyPercentageV2 from "../../Core/Modules/Chart/EbloomSmileyPercentageV2";
import EbloomAllSmileyProgressBar from "../../Core/Modules/Chart/EbloomAllSmileyProgressBar";
import CategoryTag from "../../Core/Modules/CategoryDisplay/CategoryTag";
import CommentTooltip from "../../Core/Modules/Popovers-Tooltips/CommentTooltip";
import EbloomHeatmap from "../../Core/Modules/Chart/EbloomHeatmap";
import {
    CompanyTypes,
    getEndDateAccordingToWindow,
    Modules,
    Roles,
    SettingTypes,
    SurveyQuestionRecipients
} from "../../../Utils/Global";
import Report from "./Report/Report";
import {IconButton} from "@mui/material";
import {useUserData} from "../../../hooks/userHook";
import EbloomSelectLang from "../../Core/Modules/Input/EbloomSelectLang";
import {ReactComponent as ArrowRight} from "../../Core/symbols/general/ArrowRight.svg";
import {ReactComponent as ArrowGreen} from "../../Core/symbols/general/ArrowUpGreen.svg";
import {ReactComponent as ArrowRed} from "../../Core/symbols/general/ArrowDownRed.svg";
import {ReactComponent as ArrowNeutral} from "../../Core/symbols/general/ArrowNeutral.svg";

import TagDetail from "../Modules/TagDetail";
import EbloomSpeedometerV2 from "../../Core/Modules/Chart/EbloomSpeedometerV2";
import {useBreakpoints} from "../../../hooks/breakpointsHook";
import {selectBubbleIcon} from "../../Admin/Pages/Categories/Assets/CategoriesAssets";
import ProspectModal from "../../Core/Modules/Modal/ProspectModal";
import EbloomMultipleTeamsSelect from "../../Core/Modules/Input/EbloomMultipleTeamsSelect";
import IdentityQuestionModal from "../../Core/Modules/Modal/IdentityQuestionModal";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";

const ManagerDashboard = (props) => {
    const [loadingParticipation, setLoadingParticipation] = useState(true);
    const [loadingNbSubs, setLoadingNbSubs] = useState(true);
    const [loadingToday, setLoadingToday] = useState(true);
    const [loadingAvg, setLoadingAvg] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingFromStart, setLoadingFromStart] = useState(true);
    const [loadingTeams, setLoadingTeams] = useState(true);
    const [loadingRadar, setLoadingRadar] = useState(true);
    const [loadingMostFlagged, setLoadingMostFlagged] = useState(true);
    const [loadingBubbleChart, setLoadingBubbleChart] = useState(true);
    const [loadingExportData, setLoadingExportData] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showCategoryEvolutionModal, setShowCategoryEvolutionModal] = useState(false);
    const [dataExportLang, setDataExportLang] = useState(getLanguage());
    const [reportLang, setReportLang] = useState(getLanguage());
    const [categories, setCategories] = useState([]);
    const [categoriesScores, setCategoriesScores] = useState([]);
    const [teams, setTeams] = useState([]);
    const [teamA, setTeamA] = useState([]);
    const [teamB, setTeamB] = useState([]);
    const [filters, setFilters] = useState([]);
    const [statFromStart, setStatFromStart] = useState({tableOfResults:[], nbResponse:0});
    const [nbSubs, setNbSubs] = useState(0);
    const [participationRate, setParticipationRate] = useState({participationRate:"no value", previousParticipationRateDifference: null});
    const [mostRecentQuestions, setMostRecentQuestions] = useState([]);
    const [mostFlagged, setMostFlagged] = useState([]);
    const [statOfBubble, setStatOfBubble] = useState([]);
    const [statOfAvg, setStatAvg] = useState({total:0, avg:0, limit:true, previousAvgDifference : null});
    const [radarData, setRadarData] = useState([]);
    const [shouldDownloadReport, setShouldDownloadReport] = useState(false);
    const [benchmark, setBenchmark] = useState({bloomIndex:null, categories:[], tooltip:""});
    const [tagDetail, setTagDetail] = useState(null);
    const [isModelCustomEnable, setIsModelCustomEnable] = useState(false);
    const [isEbloomModel, setIsEbloomModel] = useState(false);
    const [benchmarkStatComparator, setBenchmarkStatComparator] = useState(null);
    const [showModalProspect, setShowModalProspect] = useState(false)
    const [showTranslation, setShowTranslation] = useState(false);
    const [isTranslationEnable, setIsTranslationEnable] = useState(false);
    const [companyData, setCompanyData] = useState({bloomIndex : 0, categories :[]});
    const [showModalQuestion, setShowModalQuestion] = useState(false);
    const [idSurveyModal, setIdSurveyModal] = useState(null);

    const t = useTranslation();

    const user = useUserData();
    const bp = useBreakpoints()

    const isMoreThanHappy = user.module === "very_happy" || user.module === "super_happy" || user.module === "free" || user.module === "prospect";
    const isProspect = user.companyType === CompanyTypes.PROSPECT

    const isManager = user.roles.includes("global_manager")

    const anonymousRestriction = _.upperFirst(t("manager.dashboard.anonymousRestriction"));
    const noData = _.upperFirst(t("core.noData"));

    const benchmarkEndDate = () => {
        const currentDate = moment();
        const currentMonth = currentDate.month();
        const currentYear = currentDate.year();

        if (currentMonth < 3) {
            return moment(`${currentYear - 1}-12-31`).format("YYYY-MM-DD");
        } else if (currentMonth < 6) {
            return moment(`${currentYear}-03-31`).format("YYYY-MM-DD");
        } else if (currentMonth < 9) {
            return moment(`${currentYear}-06-30`).format("YYYY-MM-DD");
        } else {
            return moment(`${currentYear}-09-30`).format("YYYY-MM-DD");
        }
    }

    const benchmarkStartDate = moment(benchmarkEndDate()).subtract(2, 'years').add(1, "day").format("YYYY-MM-DD");

    const {isMobile, companyToEdit, teamToDisplay, startDate, endDate, timeFilterVersion,commonSurveyActivated,validTeams} = props;

    const companyStateName = !isProspect ? user.company : _.upperFirst(t("core.company.company"));

    const handleCloseModal = (event) => {
        setShowModal(false);
    }

    const handleOpenModal = (event) => {
        setShowModal(true);
    }

    const handleOpenEvolutionCategoryModal = (event) => {
        setShowCategoryEvolutionModal(true);
    }

    const handleCloseEvolutionCategoryModal = (event) => {
        setShowCategoryEvolutionModal(false);
    }

    const handleChangeLangDataExport = (event) => {
        setDataExportLang(event.target.value);
    }

    const handleChangeLangReport = (event) => {
        setReportLang(event.target.value);
    }

    const handleIdQuestionModal = (idSurvey,open) => {
        setIdSurveyModal(idSurvey);
        setShowModalQuestion(open)
    }

    const handleReportState = (value) => {
        setShouldDownloadReport(value);
        props.handleReportState(value);
    }

    const handleChangeTeam = (value,name) => {
        if(name === "teamA"){
            setTeamA(value);
        }else if(name === "teamB"){
            setTeamB(value);
        }

    };

    const checkAdvancedUserTeams = (teams) => {
        if(user.roles.includes(Roles.ADVANCED_USER[0])){
            if(teams.length > 0 && validTeams.length > 0 ){
                return teams.every((team) => validTeams.map(el => el.id).includes(team))
            }else{
                return false
            }
        }else{
            return true
        }
    }

    const displayParticipationRateDifference = () => {
        const participationRateValue =  participationRate.participationRate
        const difference = participationRate.previousParticipationRateDifference
        if(difference !== null  && difference.difference !== null){
            const diff = difference.difference
            if(diff === participationRateValue ){
                return null
            }else if(Math.round(diff) > 0){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowGreen className={""}/>
                            </div>
                            <p className={"text-strong-green text-sm"}>
                                {Math.abs(Math.round(diff))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>

            }else if (Math.round(diff) < 0 ){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center"}>
                            <div className={"scale-75"}>
                                <ArrowRed/>
                            </div>
                            <p className={"text-strong-red text-sm"}>
                                {Math.abs(Math.round(diff))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>
            }else if (Math.round(diff) === 0){
                return <div>
                    <EbloomTooltip text={_.upperFirst(t("admin.comparisonPreviousExercise",{start : moment(difference.start).format("DD/MM/YY"), end: moment(difference.end).format("DD/MM/YY") }))} >
                        <div className={"flex cursor-default justify-center items-center text-xl ebloom-text font-bold text-center ml-5"}>
                            <div className={"scale-75"}>
                                <ArrowNeutral/>
                            </div>
                            <p className={"text-grey text-sm"}>
                                {Math.abs(Math.round(diff))}
                                <span className={"font-normal"}>%</span>
                            </p>
                        </div>
                    </EbloomTooltip>
                </div>
            }
            return null
        }
        return null
    }

    const fetchTeams = () => {
        if(isMoreThanHappy){
            setLoadingTeams(true);
            const url = companyToEdit || Login.hasRole("global_admin") ? "/teams/getAll" : "/teams/getUserAdvancedCBTeamsWithUsers";
            const companyName = companyToEdit ? companyToEdit.name : companyStateName;
            HttpApi.getV2(url).then(response => {
                const company = {id:0, name:companyName, isValid:"valid"};
                if(response && response.data){
                    let teams = response.data;
                    if(isProspect){
                        teams = teams.map((el,index) => {
                            el.name = _.upperFirst(t("admin.team")) +" "+String.fromCharCode(index + 'A'.charCodeAt(0))
                            return el
                        })
                    }
                    if(checkAdvancedUserTeams([0])){
                        const copyTeams = [...teams.filter(el => el.id !== 0)]
                        copyTeams.splice(0,0, company);
                        teams = copyTeams;
                    }
                    if(Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && Roles.ADMIN_BACKOFFICE.some(el =>user.roles.includes(el))) {
                        if(teams.length > 0 && teams.find(el => el.id === -1) === undefined && benchmarkStatComparator !== null){
                            teams.push(benchmarkStatComparator)
                        }
                    }

                    if(companyToEdit !== null){
                        setTeamA([teams[0]])
                        setTeamB([teams[0]])
                        setTeams(teams);
                    }else{
                        const listTeamsSelected = teams.filter(el => teamToDisplay.map(el => el.id).includes(el.id))
                        setTeamA(listTeamsSelected)
                        setTeamB([teams[0]])
                        setTeams(teams);
                    }


                }else{
                    let teams = [company];
                    setTeamA(teams)
                    setTeamB(teams)
                    setTeams(teams);
                }
            }).catch(error => {
                //notifyError(error);
            }).finally(() => {
                setLoadingTeams(false);
            });
        }
    };

    const fetchFilters = () => {
        if(isMoreThanHappy && (user.roles.includes("global_admin") || user.roles.includes("global_backoffice"))){
            const url = "/filters/getCompanyFilters";
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setFilters(response.data);
                }else{
                    setFilters([]);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const fetchModelCustomSetting = () => {
        const url = "/settings/getForCompany/customCategories"
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setIsModelCustomEnable(Boolean(response.data.value))
            }else{
                setIsModelCustomEnable(false)
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchMostFlagged = () => {
        setLoadingMostFlagged(true);
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        const url = '/feedbacks/getMostFlaggedFeedbacks/'+ params + "/" + startDate + "/" + endDate+ "/"+commonSurveyActivated;
        HttpApi.getV2(url, Sources.MOST_FLAGGED).then(response => {
            if(response && response.data){
                setMostFlagged(response.data);
            }else{
                setMostFlagged([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingMostFlagged(false));
    };

    const fetchImportanceAndSatisfaction = () => {
        setLoadingBubbleChart(true);
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        const url = "/feedbacks/getImportanceAndSatisfaction/"+ params+ "/" + startDate + "/" + endDate+ "/"+commonSurveyActivated;
        HttpApi.getV2(url, Sources.IMPORTANCE_SATISFACTION).then(response => {
            if(response && response.data){
                setStatOfBubble(response.data);
                setLoadingBubbleChart(false);
            }else{
                setStatOfBubble([]);
                setLoadingBubbleChart(false);
            }
        }).catch(error => {
            notifyError(error);
            setLoadingBubbleChart(false);
        });
    };

    const fetchScoresCategories = () => {
        setLoadingCategories(true);
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        const url = "/feedbacks/getCategoriesScores/"+ params+ "/" + startDate + "/" + endDate+ "/"+commonSurveyActivated;
        HttpApi.getV2(url, Sources.CATEGORIES).then(response => {
                if (response && response.data) {
                    setCategoriesScores(response.data);
                } else {
                    setCategoriesScores([]);
                }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingCategories(false));
    }

    const fetchCompanyScoreCategories = () => {
        if(checkAdvancedUserTeams([0])){
            let url ;
            if(commonSurveyActivated){
                let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
                params = params.length > 0 ? params : '0'
                url = "/feedbacks/getCategoriesScores/"+ params+ "/" + startDate + "/" + endDate+ "/"+commonSurveyActivated;
            }else{
                url = "/feedbacks/getCategoriesScores/0/"+ startDate + "/" + endDate+ "/false";
            }
            HttpApi.getV2(url,Sources.COMPANY_DATA_SCORES).then(async responseCompany => {
                if (responseCompany && responseCompany.data) {
                    setCompanyData(prevState => {
                        const data = {...prevState}
                        data.categories = responseCompany.data;
                        return data;
                    })
                } else {
                    setCompanyData(prevState => {
                        const data = {...prevState}
                        data.categories = []
                        return data;
                    })
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const fetchCompanyGlobalStat = () => {
        if(checkAdvancedUserTeams([0])){
            const urlCompany = "/feedbacks/getGlobalStats/0/"+startDate + "/" + endDate + "/"+commonSurveyActivated;
            HttpApi.getV2(urlCompany, Sources.GLOBAL_STAT_COMPANY).then(response => {
                if (response && response.data) {

                    setCompanyData(prevState => {
                        const data = {...prevState}
                        data.bloomIndex = response.data.avgFeedback
                        return data
                    })
                } else {
                    setCompanyData(prevState => {
                        const data = {...prevState}
                        data.bloomIndex = 0
                        return data
                    })
                }
            }).catch(error => {
                notifyError(error)
            })
        }

    }

    const fetchGlobalStat = () => {
        setLoadingAvg(true);
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        const url = "/feedbacks/getGlobalStats/"+ params+ "/" + startDate + "/" + endDate+"/"+commonSurveyActivated;
        HttpApi.getV2(url, Sources.GLOBAL_STAT).then(response => {
            if (response && response.data) {
                setStatAvg({
                    total: response.data.nbFeedback,
                    avg: response.data.avgFeedback,
                    limit: response.data.nbFeedback < 5,
                    previousAvgDifference: response.data.previousAvgDifference
                })
                if (!companyToEdit) {
                    props.handleReportDisabled(response.data.nbFeedback < 5);
                }
            } else {
                setStatAvg({total: 0, avg: 0, limit: true, previousAvgDifference: null});
            }
        }).catch(error => {
            notifyError(error)
        }).finally(() => setLoadingAvg(false));


    }

    const fetchBenchmarkStats = () => {
        if(Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && Roles.ADMIN_BACKOFFICE.some(el => user.roles.includes(el))){
            HttpApi.getV2("/settings/getForCompany/"+ SettingTypes.BENCHMARK).then(responseShow => {
                if(responseShow && responseShow.data && Boolean(responseShow.data.value) === true){
                    HttpApi.getV2("/feedbacks/getBenchmarkCategoriesScore/"+benchmarkStartDate+"/"+benchmarkEndDate()).then(response => {
                        const categories = response.data;
                        HttpApi.getV2("/feedbacks/getBenchmarkGlobalStats/" + benchmarkStartDate + "/" + benchmarkEndDate()).then(response => {
                            const bloomdindex = response.data.avgFeedback
                            setBenchmark({bloomIndex:bloomdindex, categories:categories, tooltip: _.upperFirst(t("manager.dashboard.benchmarkTooltip", {nbFeedbacks:response.data.nbFeedback, nbCompanies:response.data.nbCompanies, startDate:moment(benchmarkStartDate, "YYYY-MM-DD").locale(getLanguage()).format("MMMM YYYY")}))});
                            setBenchmarkStatComparator({id:-1, name:"Benchmark", isValid:"valid", tooltip:_.upperFirst(t("manager.dashboard.benchmarkTooltip", {nbFeedbacks:response.data.nbFeedback, nbCompanies:response.data.nbCompanies, startDate:moment(benchmarkStartDate, "YYYY-MM-DD").locale(getLanguage()).format("MMMM YYYY")}))});
                        })
                    })
                }
            })
        }
    }

    const fetchMostRecentFeedback = () => {
        setLoadingToday(true);
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        const url = '/feedbacks/getMostRecentFeedbacks/'+ params + "/" + startDate + "/" + endDate+ "/"+commonSurveyActivated;
        HttpApi.getV2(url, Sources.MOST_RECENT).then(response => {
            if (response && response.data && response.data !== "limitResult") {
                setMostRecentQuestions(response.data);
            }else{
                setMostRecentQuestions([]);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingToday(false);
        })
    };

    const fetchScoreFromStart = () => {
        setLoadingFromStart(true);
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        let url = "/feedbacks/getSmileyScores/"+params + "/" + startDate + "/" + endDate + "/"+commonSurveyActivated;
        HttpApi.getV2(url, Sources.SCORE_FROM_START).then(response => {
            if(response && response.data){
                setStatFromStart(response.data);
            }else{
                setStatFromStart({tableOfResults:[], nbResponse:0});
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingFromStart(false);
        })
    };

    const fetchNbSubs = () => {
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        const teamIsNotCompany = !( teamToDisplay && teamToDisplay.length === 1 && teamToDisplay[0].id === 0)
        let url = (teamToDisplay && teamIsNotCompany && params.length > 0) ? "/teams/multiple/count/" + params + "/" + startDate + "/" + endDate : "/users/getCountEmployees/" + endDate;
        setLoadingNbSubs(true);
        HttpApi.getV2(url, Sources.NB_SUBS).then(response => {
            if(response && response.data){
                setNbSubs(response.data);
            }else{
                setNbSubs(0);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingNbSubs(false));
    };

    const fetchParticipationRate = () => {
        let params = teamToDisplay ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
        params = params.length > 0 ? params : '0'
        let url = '/feedbacks/getParticipationRate/'+params + "/" + startDate + "/" + endDate+"/"+commonSurveyActivated;
        setLoadingParticipation(true);
        HttpApi.getV2(url, Sources.PARTICIPATION_RATE).then(response => {
            if(response && response.data && response.data.participationRate ){
                setParticipationRate({participationRate: response.data.participationRate, previousParticipationRateDifference: response.data.previousParticipationRateDifference});
            }else{
                setParticipationRate({participationRate: t("webloom.NA"), previousParticipationRateDifference: null});
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => setLoadingParticipation(false));
    };

    const fetchCategories = () => {
        HttpApi.getV2("/categories/getMainCategories").then(response => {
            if(response && response.data){
                setCategories(response.data);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchRadarData = (A=[], B=[]) => {
        if(isMoreThanHappy){
            setLoadingRadar(true);
            let listA = A
            let listB = B
            const isBenchmarkSelectedA = A && A.length > 0 && A.filter(el => el.id === -1).length > 0 ;
            const isBenchmarkSelectedB = B && B.length > 0 && B.filter(el => el.id === -1).length > 0 ;
            if(isBenchmarkSelectedA){
                listA = listA.filter(el => el.id !== -1)
            }

            if(isBenchmarkSelectedB){
                listB = listB.filter(el => el.id !== -1)
            }

            const teamsIdA = listA && listA.length > 0 ? listA.map(el => String(el.id)).join('-') : '0'
            const teamsIdB = listB && listB.length > 0 ? listB.map(el => String(el.id)).join('-') : '0'
            const url = "/feedbacks/getRadarData/"+teamsIdA + "/" + teamsIdB+"/"+ startDate+"/"+endDate+"/"+commonSurveyActivated;
            HttpApi.getV2(url, Sources.RADAR_DATA).then(response => {
                if(response && response.data){
                    let radarData = response.data;
                    if(radarData.enoughValue){
                        if(isBenchmarkSelectedA){//benchmark for team A
                            radarData.tableOfAvg.forEach(data => {
                                data.A = benchmark.categories.find(el => el.category.name === data.subject).score;
                            })
                        }
                        if(isBenchmarkSelectedB){//benchmark for team B
                            radarData.tableOfAvg.forEach(data => {
                                data.B = benchmark.categories.find(el => el.category.name === data.subject).score;
                            })
                        }
                    }
                    const filteredTableOfAvg = radarData.tableOfAvg.filter(el => el.A !== null && el.B !== null && el.A >= 0 && el.B >= 0)
                    let filteredRadar = {
                        tableOfAvg : filteredTableOfAvg.length >= 3 ? filteredTableOfAvg.sort((a,b) => a.category.position - b.category.position) : radarData.tableOfAvg.sort((a,b) => a.category.position - b.category.position),
                        enoughValue : filteredTableOfAvg.length >= 3
                    }
                    if(!filteredRadar.enoughValue){
                        filteredRadar.tableOfAvg.forEach(el => {
                            el.A = null
                            el.B = null
                        })
                    }
                    setRadarData(filteredRadar);
                }else{
                    setRadarData([]);
                }
            }).catch(error => {
                notifyError(error);
            }).finally(() => {
                setLoadingRadar(false)
            });
        }
    };

    const fetchModelCustomization = () => {
        HttpApi.getV2("/categories/getAllCustomCategories").then(response => {
            if(response && response.data){
                const equalToEBloom = checkEquality(response.data.companyModelConfirmed, response.data.ebloomModel)
                setIsEbloomModel(response.data.companyModelConfirmed.length === 0 ? true : equalToEBloom)
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchTranslationSetting = () => {
        HttpApi.getV2("/settings/getForCompany/"+ SettingTypes.SHOW_TRANSLATION).then(responseShow => {
            HttpApi.getV2("/settings/getForCompany/"+ SettingTypes.TRANSLATION).then(response => {
                if(responseShow && responseShow.data && response && response.data){
                    const settingShowTranslation = responseShow.data
                    const settingTranslation = response.data
                    setShowTranslation(Boolean(settingShowTranslation.value))
                    setIsTranslationEnable(Boolean(settingTranslation.value))
                }
            }).catch(error => {
                notifyError(error);
            })
        }).catch(error => {
            notifyError(error);
        })
    }

    const exportData = () => {
        if(isMoreThanHappy){
            const params = teamToDisplay && !(teamToDisplay.length === 1 && teamToDisplay[0].id === 0) ? teamToDisplay.map(el => String(el.id)).join('-') : null
            setLoadingExportData(true);
            HttpApi.getWithResponseTypeV2("/questions/export/"+(params ? params : 0)+"/" + startDate + "/" + endDate + "/" + dataExportLang + "/" + commonSurveyActivated).then((response) => {
                let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                if(startDate && endDate){
                    fileSaver.saveAs(blob, 'ebloom_' + startDate + '_' + endDate + '.xlsx');
                }else{
                    fileSaver.saveAs(blob, 'ebloom.xlsx');
                }

                notifySuccess("Data exported to excel file");
            }).catch(error => {
                notifyError(error);
            }).finally(() => setLoadingExportData(false));
        }
    }

    const checkEquality = (a1, a2) => {
        if(a1.length !== a2.length){
            return false
        }
        for (const el of a1) {
            const result = a2.filter(c => c.name === el.name && parseInt(c.icon) === parseInt(el.icon) && c.color === el.color)
            if(result.length > 0){
                const tagsSame = checkTags(el.tags,result[0].tags)
                if(!tagsSame){
                    return false
                }
            }else{
                return false
            }
        }
        return true
    }

    const checkTags = (t1,t2) => {
        for (const el of t1) {
            const result = t2.filter(t => t.id_tag === el.id_tag)
            if(result.length <= 0){
                return false
            }
        }
        return true
    }


    const displayDate = (date) => {
        if(moment(date).format("DD/MM") === moment().format("DD/MM")){
            return _.capitalize(t("core.day.today"));
        }else{
            return moment(date).format("DD/MM");
        }
    };

    const displayStartDate = (date) => {
        return moment(date).format("DD/MM");
    }

    const cutName = (name) => {
        if(name && !loadingBubbleChart) {
            let width = 150;
            const w = document.getElementsByClassName("focus-matrix-legend")[0];
            if(w){
                width = w.offsetWidth;
            }
            const words = name.split(" ");
            const availableSize = width - 8; //remove size of the icon + some margin

            let nbPossibleLettersWords = Math.floor(availableSize / 7);
            let lettersInWords = name.length;
            if (lettersInWords <= nbPossibleLettersWords) {
                return name;
            } else {
                let nbPossibleLettersPerWord = nbPossibleLettersWords / words.length;
                let smallerWords = words.filter(el => el.length < nbPossibleLettersPerWord)
                let largerWords = words.filter(el => el.length > nbPossibleLettersPerWord).map(el => el = {
                    name: el,
                    extraLetters: el.length - nbPossibleLettersPerWord
                });
                let goodWords = words.filter(el => el.length === nbPossibleLettersPerWord);
                let nbExtraLetters = smallerWords.map(el => (nbPossibleLettersPerWord - el.length)).reduce((partialSum, a) => partialSum + a, 0)
                while (nbExtraLetters > 0 && largerWords.find(el => el.extraLetters > 0) !== undefined) {
                    for (let i = 0; i < largerWords.length; i++) {
                        if (largerWords[i].extraLetters > 0 && nbExtraLetters > 0) {
                            largerWords[i].extraLetters--;
                            nbExtraLetters--;
                        }
                    }
                }

                let newWord = "";
                for (let i = 0; i < words.length; i++) {
                    let word = words[i];
                    if (smallerWords.includes(word) || goodWords.includes(word)) {
                        newWord += word;
                    } else if (largerWords.find(el => el.name === word) !== undefined) {
                        const index = word.length - largerWords.find(el => el.name === word).extraLetters - 1;
                        newWord += word.slice(0, index);
                        if (index > 0) {
                            newWord += ".";
                        }
                    }
                    if (i < words.length - 1) {
                        newWord += " ";
                    }
                }
                return newWord;
            }
        }

    }

    useEffect(() => {
        if((teamToDisplay !== null && teamToDisplay.length > 0  && timeFilterVersion !== null) || (Login.hasRole("global_backoffice") && companyToEdit !== null && timeFilterVersion !== null) || (!isMoreThanHappy && timeFilterVersion !== null)){
            fetchTeams();
            fetchMostRecentFeedback();
            fetchScoreFromStart();
            fetchNbSubs();
            fetchFilters();
            fetchParticipationRate();
            fetchMostFlagged();
            fetchImportanceAndSatisfaction();
            fetchGlobalStat();
            fetchScoresCategories();
            fetchCategories()
            fetchModelCustomSetting()
            fetchModelCustomization()
            fetchCompanyScoreCategories()
            fetchCompanyGlobalStat()
        }

    }, [companyToEdit, teamToDisplay, timeFilterVersion,commonSurveyActivated,validTeams])

    useEffect(() => {
        fetchRadarData(teamA, teamB);
    }, [teams, teamA, teamB]);

    useEffect(() => {
        fetchBenchmarkStats();
        fetchTranslationSetting()
    },[])

    useEffect(() => {
        if(teams.length > 0 && teams.find(el => el.id === -1) === undefined && benchmarkStatComparator !== null){
            setTeams(prevState => [...prevState,benchmarkStatComparator])
        }
    }, [teams,benchmarkStatComparator,benchmark]);

    return (
        <div className={"grid grid-cols-12"}>
            <div className={"mb-20 col-span-full"}>
                <div className={"w-full grid grid-cols-12 gap-x-15"}>
                    <div className={"mb-20 " + (!bp.isTabletLandscapeOrLess() ? "col-start-1 col-span-7 " : "col-span-full")}>
                        <Container fluid className={"px-0"}>
                            <Card className={"bg-bg-blue border border-contrast-purple"} >

                                {
                                    !bp.isTabletLandscapeOrLess() ?
                                        <Row>
                                            <Col md={5}>
                                                <Card style={{border:"none", borderRight: "1px solid " + Colors.EBLOOM_CONTRAST_PURPLE, borderRadius:0, backgroundColor:"transparent", height: 283}}>
                                                    <Card.Body style={{paddingBottom:0}}>
                                                        <div style={{float:"right"}}>
                                                            <EbloomTooltip text={_.upperFirst(t("manager.dashboard.bloomHelper"))}>
                                                                <div className={"info_icon very_small_smile"}/>
                                                            </EbloomTooltip>
                                                        </div>
                                                        <Card.Title className={"ebloom-normal-title"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE, textAlign:"center"}}>{_.upperFirst(t("manager.dashboard.bloomIndex"))}</Card.Title>
                                                        {
                                                            loadingAvg ?
                                                                <LoadingView />
                                                                :
                                                                !statOfAvg.total || statOfAvg.total <= 0 ?
                                                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{noData}</p>
                                                                    :
                                                                    statOfAvg.limit ?
                                                                        <p style={{color:"black"}}>{anonymousRestriction}</p>
                                                                        :
                                                                        <div className={"flex justify-center mt-25"}>
                                                                            <div className={"mx-auto "}>
                                                                                <EbloomSpeedometerV2 score={statOfAvg.avg} showDifference={true} difference={statOfAvg.previousAvgDifference}/>
                                                                            </div>
                                                                        </div>
                                                        }

                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={7}>
                                                <Card style={{backgroundColor:"transparent", border:"none"}}>
                                                    <Card.Body>
                                                        <div style={{float:"right"}}>
                                                            <EbloomTooltip text={_.upperFirst(t("manager.dashboard.smileyHelper"))}>
                                                                <div className={"info_icon very_small_smile"}/>
                                                            </EbloomTooltip>
                                                        </div>
                                                        <Card.Title className={"ebloom-normal-title"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE, textAlign:"center"}}>{"Smiley Score"}</Card.Title>
                                                        {
                                                            loadingAvg || loadingFromStart ?
                                                                <LoadingView/>
                                                                :
                                                                !statOfAvg.total || statOfAvg.total <= 0 ?
                                                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em", marginTop:20}}>{noData}</p>
                                                                    :
                                                                    statOfAvg.limit ?
                                                                        <p style={{color:"black"}}>{anonymousRestriction}</p>
                                                                        :
                                                                        <Row className={"flex justify-center items-center mt-30"}>
                                                                            <div style={{width:"100%", margin:"auto"}}>
                                                                                {
                                                                                    loadingFromStart ?
                                                                                        <LoadingView />
                                                                                        :
                                                                                        <EbloomSmileyPercentageV2 value={statFromStart} isMobile={isMobile}/>
                                                                                }
                                                                            </div>
                                                                        </Row>
                                                        }

                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        :
                                        <div className={"h-full grid grid-rows-2 divide-y divide-contrast-purple"} >
                                            <div className={"row-start-1"}>
                                                <Card className={"bg-transparent border-0 h-full relative"}>
                                                    <Card.Body className={"mb-10"}>
                                                        <div className={"absolute right-16"}>
                                                            <EbloomTooltip text={_.upperFirst(t("manager.dashboard.bloomHelper"))}>
                                                                <div className={"info_icon very_small_smile"}/>
                                                            </EbloomTooltip>
                                                        </div>
                                                        <Card.Title className={"ebloom-normal-title text-center text-contrast-purple"}>{_.upperFirst(t("manager.dashboard.bloomIndex"))}</Card.Title>
                                                        {
                                                            loadingAvg ?
                                                                <LoadingView />
                                                                :
                                                                !statOfAvg.total || statOfAvg.total <= 0 ?
                                                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{noData}</p>
                                                                    :
                                                                    statOfAvg.limit ?
                                                                        <p style={{color:"black"}}>{anonymousRestriction}</p>
                                                                        :
                                                                        <div className={"flex mt-25"}>
                                                                            <div className={"m-auto"}>
                                                                                <EbloomSpeedometerV2 score={statOfAvg.avg} showDifference={true} difference={statOfAvg.previousAvgDifference}/>
                                                                            </div>
                                                                        </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className={"row-start-2"}>
                                                <Card className={"bg-transparent border-0 relative"}>
                                                    <Card.Body>
                                                        <div className={"absolute right-16"}>
                                                            <EbloomTooltip text={_.upperFirst(t("manager.dashboard.smileyHelper"))}>
                                                                <div className={"info_icon very_small_smile"}/>
                                                            </EbloomTooltip>
                                                        </div>
                                                        <Card.Title className={"ebloom-normal-title text-center text-contrast-purple"}>{"Smiley Score"}</Card.Title>
                                                        {
                                                            loadingAvg || loadingFromStart ?
                                                                <LoadingView/>
                                                                :
                                                                !statOfAvg.total || statOfAvg.total <= 0 ?
                                                                    <p style={{color:"black", textAlign:"center", fontSize:"1.5em", marginTop:20}}>{noData}</p>
                                                                    :
                                                                    statOfAvg.limit ?
                                                                        <p style={{color:"black"}}>{anonymousRestriction}</p>
                                                                        :
                                                                        <Row className={"flex justify-center items-center mt-30"}>
                                                                            <div className={"w-full"}>
                                                                                {
                                                                                    loadingFromStart ?
                                                                                        <LoadingView />
                                                                                        :
                                                                                        <EbloomSmileyPercentageV2 value={statFromStart} isMobile={isMobile}/>
                                                                                }
                                                                            </div>
                                                                        </Row>
                                                        }

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                }
                            </Card>
                        </Container>
                    </div>
                    <div className={" w-full h-full " + (!bp.isTabletLandscapeOrLess() ? "col-end-13 col-span-5" : "col-span-full")}>
                        <DashboardCard title={_.upperFirst(t("manager.dashboard.participation"))} showInfo={false} minHeight={283}>
                            {
                                (loadingParticipation || loadingNbSubs || loadingAvg) ?
                                    <LoadingView/>
                                    :
                                    <div className={"h-full w-full grid grid-rows-3 " + (!bp.isTabletLandscapeOrLess() ? "gap-y-30" : "gap-y-15")}>
                                        <div className={"row-start-1 grid grid-cols-12 gap-x-30"}>
                                            <div className={"col-start-1 col-span-2 "}>
                                                <div className={"hands_icon very_small_smile"}/>
                                            </div>
                                            <div className={"col-start-3 col-span-7 "}>
                                                <span className={"ebloom-normal-text"}>{_.upperFirst(t("manager.dashboard.participationRate")) + " (%)"}</span>
                                            </div>
                                            <div className={"col-start-10 col-end-13 flex justify-start"}>
                                                <div>
                                                    <Badge bg={""} className={"ebloom-normal-text bg-contrast-purple text-white float-left"}>{participationRate.participationRate}</Badge>
                                                </div>
                                                <div>
                                                    {displayParticipationRateDifference()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row-start-2 grid grid-cols-12 gap-x-30"}>
                                            <div className={"col-start-1 col-span-2 "}>
                                                <div className={"users_group_icon very_small_smile"}/>
                                            </div>
                                            <div className={"col-start-3 col-span-7 "}>
                                                <span className={"ebloom-normal-text"}>{_.upperFirst(t("manager.dashboard.numberSubscription"))}</span>
                                            </div>
                                            <div className={"col-start-10 col-end-13 flex justify-start"}>
                                                <div>
                                                    <Badge bg={""} className={"ebloom-normal-text text-white float-left bg-contrast-purple"}>{nbSubs}</Badge>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row-start-3 grid grid-cols-12 gap-x-30"}>
                                            <div className={"col-start-1 col-span-2 "}>
                                                <div className={"nbCB_icon very_small_smile"}/>
                                            </div>
                                            <div className={"col-start-3 col-span-7 "}>
                                                <span className={"ebloom-normal-text"} style={{width:195}}>{_.upperFirst(t("manager.dashboard.nbAnswers"))}</span>
                                            </div>
                                            <div className={"col-start-10 col-end-13 flex justify-start "}>
                                                <div>
                                                    <Badge bg={""} className={"ebloom-normal-text text-white float-left bg-contrast-purple"}>{statOfAvg.total}</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </DashboardCard>
                    </div>
                </div>
            </div>
            <div className={"col-span-full h-full"}>
                <Row className={"mb-20"}>
                    <Col md={6} sm={12} className={"mb-20"}>
                        <DashboardCard minHeight={976} title={_.upperFirst(t("manager.dashboard.mostRecent"))} showInfo={false}>
                            {
                                loadingToday ?
                                    <LoadingView/>
                                    :

                                    <ListGroup variant="flush" className={"overflow-y-auto"} style={{height:865}}>
                                        {
                                            mostRecentQuestions.length === 0 ?
                                                <p style={{textAlign:"center", fontSize:"1.5em", marginTop: 488}}>{_.upperFirst(t("core.noData"))}</p>
                                                :
                                                mostRecentQuestions.map((question, index) => (
                                                    <ListGroupItem key={index} style={{border:"none"}}>
                                                        <Row className={"ebloom-normal-text"}>
                                                            <Col md={3} style={{paddingRight:0, marginRight:0, marginLeft:0}}>
                                                                <div className={"left"}>
                                                                    <div className={"inline-block h-30 w-30"}>
                                                                        {selectBubbleIcon(question.icon,question.color)}
                                                                    </div>
                                                                    {
                                                                        (moment(question.date).isAfter(moment("08/05/2022", "DD/MM/YYYY"))) ?
                                                                            <EbloomTooltip text={_.upperFirst(t("core.notification.openFromTo", {param1:displayStartDate(question.date), param2:displayStartDate(getEndDateAccordingToWindow(question.date, question.opening_time))}))}>
                                                                                <div className={"ebloom-normal-text inline_icon"} style={{color:Colors.EBLOOM_LOGO_BLUE, position:"relative", bottom:10, left:10, cursor:"default"}}>{displayDate(question.date)}</div>
                                                                            </EbloomTooltip>
                                                                            :
                                                                            <div className={"ebloom-normal-text inline_icon"} style={{color:Colors.EBLOOM_LOGO_BLUE, position:"relative", bottom:10, left:10, cursor:"default"}}>{displayDate(question.date)}</div>
                                                                    }

                                                                </div>
                                                            </Col>
                                                            <Col style={{paddingLeft:0, marginTop:isMobile ? 20 : 0}}>
                                                                <div>
                                                                    <span>{question.text}</span>
                                                                    {
                                                                        isMoreThanHappy && question?.enableIdentityVersion === true &&
                                                                        <IconButton className={"text-purple ml-5"} onClick={() => handleIdQuestionModal(question.id_survey_question,true)} >
                                                                            <TypeSpecimenIcon/>
                                                                        </IconButton>

                                                                    }
                                                                </div>
                                                            </Col>
                                                            {
                                                                !isProspect && mostRecentQuestions.filter(el => el.recipients === SurveyQuestionRecipients.TEAMS).length > 0 && (!commonSurveyActivated || (teamToDisplay?.length === 1 && teamToDisplay[0].id === 0)) &&
                                                                <Col md={{order:"last", span:2}}>
                                                                    {
                                                                        !!teamToDisplay && teamToDisplay.length > 0 && (teamToDisplay[0].id === 0 || teamToDisplay.filter(el => el.id !== 0 ).length > 1) && question.survey_teams?.length > 0 ?
                                                                            question.survey_teams?.length === 1 ?
                                                                                <div>
                                                                                    <Badge style={{backgroundColor :  Colors.EBLOOM_PURPLE}} pill
                                                                                           className={"text-white"}>
                                                                                        {question.survey_teams[0].team.name}
                                                                                    </Badge>
                                                                                </div>
                                                                                :
                                                                                <EbloomTooltip text={question.survey_teams.map(el => el.team.name).join('\n')}>
                                                                                    <Badge style={{backgroundColor :  Colors.EBLOOM_PURPLE}} pill
                                                                                           className={"text-white"}>
                                                                                        {question.survey_teams.length + " " +  _.lowerFirst(t("admin.teams"))}
                                                                                    </Badge>
                                                                                </EbloomTooltip>
                                                                            : null
                                                                    }
                                                                </Col>
                                                            }
                                                        </Row>

                                                {
                                                    question.nbResponse < 5 ?
                                                        <p className={"text-center mt-15 ebloom-normal-text"}>{anonymousRestriction}</p>
                                                        :
                                                        <Row className={"mt-20 mb-20"}>
                                                            <Col md={{span:5}} style={{borderRight:isMobile ? "none" : "1px solid " + Colors.EBLOOM_CONTRAST_PURPLE, paddingLeft:0, maxHeight:"auto"}}>
                                                                <div className={"center"}>
                                                                    <EbloomSpeedometerV2 score={question.score} width={200}/>
                                                                </div>
                                                                    <Row className={"mt-10 z-10"} style={{marginLeft:isMobile ? 20 : 0, marginBottom:isMobile ? 20 : 0}}>
                                                                    <Col md={{span:4, offset:0}} xs={{span:4, offset:0}} style={{paddingRight:0, marginTop:-4, marginLeft:0}}>
                                                                        <div className={"hands_icon very_small_smile"} style={{display:"inline-block", marginRight:5, marginBottom:-8}}/><span className={"ebloom-normal-text"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}>{question.nbResponse}</span>
                                                                    </Col>
                                                                    <Col style={{cursor:"pointer", paddingRight:0}}>
                                                                        <CommentTooltip comments={question.comments} isDashboardManager={true} isTranslationEnable={isTranslationEnable} showTranslation={showTranslation}/>
                                                                    </Col>
                                                                    <Col style={{paddingRight:0}}>
                                                                        <div className={"flag_contrast micro_icon"} style={{display:"inline-block", marginRight:3, marginBottom:-8}}/><span className={"ebloom-normal-text"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}>{question.nbFlags}<span className={"text-xs"}>%</span></span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col style={{paddingLeft:isMobile ? 43 : 30, maxHeight:"auto"}}>
                                                                <EbloomAllSmileyProgressBar showRepartition nbResponse0={question.nbResponse0} nbResponse1={question.nbResponse1} nbResponse2={question.nbResponse2} nbResponse3={question.nbResponse3} nbResponse4={question.nbResponse4} nbResponse5={question.nbResponse5} total={question.nbResponse} barHeight={isMobile ? 8 : 10} barWidth={isMobile ? 100 : 150} space={5}/>
                                                            </Col>
                                                        </Row>
                                                }
                                            </ListGroupItem>
                                        ))
                                    }
                                 </ListGroup>

                            }
                        </DashboardCard>
                    </Col>
                    <Col md={6} sm={12}>
                        <DashboardCard title={_.upperFirst(t("manager.dashboard.mostFlagged"))} info={_.upperFirst(t("manager.dashboard.mostFlaggedHelper"))} minHeight={976}>
                            {
                                loadingMostFlagged ?
                                    <LoadingView/>
                                    :

                                    <ListGroup variant="flush" className={"overflow-y-auto"} style={{height:870}}>
                                        {
                                            mostFlagged.length === 0 ?
                                                <p style={{textAlign:"center", fontSize:"1.5em", marginTop: 430}}>{_.upperFirst(t("core.noData"))}</p>
                                                :
                                                mostFlagged.map((question, index) => (
                                                    <ListGroupItem key={index} style={{border:"none"}}>
                                                        <Row className={"ebloom-normal-text"}>
                                                            <Col md={3} style={{paddingRight:0}}>
                                                                <div className={"left"}>
                                                                    <div className={"inline-block h-30 w-30"}>
                                                                        {selectBubbleIcon(question.icon,question.color)}
                                                                    </div>
                                                                    {
                                                                        (moment(question.date).isAfter(moment("08/05/2022", "DD/MM/YYYY"))) ?
                                                                            <EbloomTooltip text={_.upperFirst(t("core.notification.openFromTo", {param1:displayStartDate(question.date), param2:displayStartDate(getEndDateAccordingToWindow(question.date, question.opening_time))}))}>
                                                                                <div className={"ebloom-normal-text inline_icon"} style={{color:Colors.EBLOOM_LOGO_BLUE, position:"relative", bottom:10, left:10}}>{displayDate(question.date)}</div>
                                                                            </EbloomTooltip>
                                                                            :
                                                                            <div className={"ebloom-normal-text inline_icon"} style={{color:Colors.EBLOOM_LOGO_BLUE, position:"relative", bottom:10, left:10}}>{displayDate(question.date)}</div>

                                                                    }

                                                                </div>
                                                            </Col>
                                                            <Col style={{paddingLeft: 0}} >
                                                                <div>
                                                                    <span>{question.text}</span>
                                                                    {
                                                                        isMoreThanHappy && question?.enableIdentityVersion === true &&
                                                                        <IconButton className={"text-purple ml-5"}
                                                                                    onClick={() => handleIdQuestionModal(question.id_survey_question, true)}>
                                                                            <TypeSpecimenIcon/>
                                                                        </IconButton>

                                                                    }
                                                                </div>
                                                            </Col>
                                                            {
                                                                !isProspect && mostFlagged.filter(el => el.recipients === SurveyQuestionRecipients.TEAMS).length > 0 && !commonSurveyActivated &&
                                                                <Col md={{order:"last", span:2}}>
                                                                    {
                                                                        !!teamToDisplay && teamToDisplay.length > 0 && (teamToDisplay[0].id === 0 || teamToDisplay.filter(el => el.id !== 0 ).length > 1)&& question.survey_teams?.length > 0 ?
                                                                            question.survey_teams?.length === 1 ?
                                                                                <div>
                                                                                    <Badge style={{backgroundColor : user.companyGroup && user.companyGroup.colors ? user.companyGroup.colors.primary : Colors.EBLOOM_PURPLE}} pill
                                                                                           className={"text-white"}>
                                                                                        {question.survey_teams[0].team.name}
                                                                                    </Badge>
                                                                                </div>
                                                                                :
                                                                                <EbloomTooltip text={question.survey_teams.map(el => el.team.name).join('\n')}>
                                                                                    <Badge style={{backgroundColor :  Colors.EBLOOM_PURPLE}} pill
                                                                                           className={"text-white"}>
                                                                                        {question.survey_teams.length + " " +  _.lowerFirst(t("admin.teams"))}
                                                                                    </Badge>
                                                                                </EbloomTooltip>
                                                                            : null
                                                                    }
                                                                </Col>
                                                            }
                                                        </Row>
                                                        {
                                                            question.nbResponse < 5 ?
                                                                <p style={{
                                                                    textAlign: "center",
                                                                    marginTop: 15
                                                                }}>{anonymousRestriction}</p>
                                                                :
                                                                <Row className={"mt20 mb20"}>
                                                                    <Col md={5} className={"justify-center"} style={{
                                                                        borderRight: isMobile ? "none" : "1px solid " + Colors.EBLOOM_CONTRAST_PURPLE,
                                                                        paddingLeft: 0
                                                                    }}>
                                                                        <EbloomSpeedometerV2 score={question.score}
                                                                                             width={200}/>
                                                                        <Row className={"z-10 mt-10"} style={{marginLeft:isMobile ? 20 : 0, marginBottom:isMobile ? 10 : 0}}>
                                                                    <Col style={{paddingRight:0, marginTop:-4}}>
                                                                        <div className={"hands_icon very_small_smile"} style={{display:"inline-block", marginRight:5, marginBottom:-8}}/><span className={"ebloom-normal-text"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}>{question.nbResponse}</span>
                                                                    </Col>
                                                                    <Col style={{cursor:"pointer", paddingRight:0}}>
                                                                       <CommentTooltip comments={question.comments} isDashboardManager={true} isTranslationEnable={isTranslationEnable} showTranslation={showTranslation} />
                                                                    </Col>
                                                                    <Col style={{paddingRight:0}}>
                                                                        <div className={"flag_contrast micro_icon"} style={{display:"inline-block", marginRight:3, marginBottom:-8}}/><span className={"ebloom-normal-text"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE}}>{question.nbFlags}<span className={"text-xs"}>%</span></span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className={"pl-30"}>
                                                                <EbloomAllSmileyProgressBar showRepartition nbResponse0={question.nbResponse0} nbResponse1={question.nbResponse1} nbResponse2={question.nbResponse2} nbResponse3={question.nbResponse3} nbResponse4={question.nbResponse4} nbResponse5={question.nbResponse5} total={question.nbResponse} barHeight={10} barWidth={150} space={5}/>
                                                            </Col>
                                                        </Row>
                                                }
                                            </ListGroupItem>
                                        ))
                                    }
                                </ListGroup>

                            }
                        </DashboardCard>
                    </Col>
                </Row>
                <Row className={"mb20"}>
                    <Col md={12} sm={12}>
                        <DashboardCard paddingLeft={15} paddingRight={15} title={_.upperFirst(t("core.category.categories"))} info={_.upperFirst(t("manager.dashboard.categoryHelper"))} showButton={companyToEdit ? true : ((teamToDisplay !== null && teamToDisplay.length === 1 && teamToDisplay[0].id === 0)  && !isMobile)} buttonVariant="link" buttonText={_.capitalize(t("manager.dashboard.seeEvolution"))} handleButton={handleOpenEvolutionCategoryModal}>
                            {
                                (loadingAvg || loadingCategories) ?
                                    <LoadingView/>
                                    :
                                    !statOfAvg.total || statOfAvg.total <= 0 ?
                                        <p style={{color:"black", textAlign:"center", fontSize:"1.5em"}}>{noData}</p>
                                        :
                                        statOfAvg.limit ?
                                            <p>{anonymousRestriction}</p>
                                            :categoriesScores.length > 5 ?
                                                <div className={"w-full"}>
                                                    {[0, 1].map(index => (
                                                        <div className={"w-full " + (bp.isTabletLandscapeOrLess() ? "flex flex-col justify-center items-center" : "flex flex-row justify-around items-center")}>
                                                            {
                                                                categoriesScores.sort((a, b) => a.category.position - b.category.position).slice(index === 0 ? 0 : Math.floor(categoriesScores.length / 2), index === 0 ? Math.floor(categoriesScores.length / 2) : categoriesScores.length).map((category, index) => (
                                                                    <div key={index}
                                                                         className={"mb-20 flex flex-col justify-center items-center w-1/5 " + (bp.isTabletLandscapeOrLess() && "mb-30")}>
                                                                        <div
                                                                            className={"flex items-center justify-center pointer"}
                                                                            onClick={() => {
                                                                                setTagDetail(category)
                                                                            }}>
                                                                            <CategoryTag
                                                                                modelCustomEnable={isModelCustomEnable}
                                                                                category={category.category}
                                                                                width={200}/>
                                                                            {
                                                                                category.score !== null && category.score !== -1 &&
                                                                                <ArrowRight className={"ml-5"}/>
                                                                            }
                                                                        </div>
                                                                        <div style={{
                                                                            marginTop: 15,
                                                                            opacity: (category.score === null || category.score === -1) ? 0.4 : 1
                                                                        }} className={"pointer"}
                                                                             onClick={() => {
                                                                                 setTagDetail(category)
                                                                             }}>
                                                                            <EbloomSpeedometerV2
                                                                                showDifference={true}
                                                                                difference={category.previousExerciceDifference}
                                                                                score={category.score}
                                                                                width={200}/>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                                </div>

                                                :
                                                <div className={"w-full flex flex-row justify-around items-center"}>
                                                    {
                                                        categoriesScores.sort((a, b) => a.category.position- b.category.position).map((category, index) => (
                                                            <div key={index} className={"mb-20 flex flex-col justify-center items-center w-1/5 " + (bp.isTabletLandscapeOrLess() && "mb-30" )}>
                                                                <div className={"flex items-center justify-center pointer"} onClick={() => {setTagDetail(category)}}>
                                                                    <CategoryTag category={category.category} width={200} modelCustomEnable={isModelCustomEnable}/>
                                                                    {
                                                                        category.score !== null && category.score !== -1 &&
                                                                        <ArrowRight className={"ml-5"}/>
                                                                    }
                                                                </div>
                                                                <div style={{marginTop:15, opacity:(category.score === null || category.score === -1) ? 0.4 : 1}} className={"pointer"} onClick={() => {setTagDetail(category)}}>
                                                                    <EbloomSpeedometerV2 showDifference={true} difference={category.previousExerciceDifference} score={category.score} width={200}/>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                            }
                        </DashboardCard>
                    </Col>
                </Row>
                <Row className={"mb-20"}>
                    {
                        isMoreThanHappy &&
                        <Col md={6} sm={12} style={{marginBottom: bp.isTabletLandscapeOrLess() ? 20 : 0}}>
                            <DashboardCard minHeight={600} title={_.upperFirst(t("manager.dashboard.scoreComparator"))} info={_.upperFirst(t("manager.dashboard.scoreHelper"))}>
                                {
                                    loadingTeams ?
                                        <LoadingView/>
                                        :
                                        <div>
                                            <Row className={"mt-30"}>
                                                <Col>
                                                    <EbloomMultipleTeamsSelect label={_.upperFirst(t("admin.team")+'(s)')} value={teamA} name={"teamA"} handleChange={handleChangeTeam} options={teams.filter(el => el.isValid === "valid")}  size={"small"} color={"purple"} companyToEdit={companyToEdit}/>
                                                </Col>
                                                <Col>
                                                    <EbloomMultipleTeamsSelect label={_.upperFirst(t("admin.team")+'(s)')} value={teamB} name={"teamB"} handleChange={handleChangeTeam} options={teams.filter(el => el.isValid === "valid")}  size={"small"} color={"blue"} companyToEdit={companyToEdit}/>
                                                </Col>
                                            </Row>
                                            {
                                                (false === loadingRadar && false === radarData.enoughValue) &&
                                                <div className={"ebloom-text ebloom-xtra-large-text w-full center absolute bottom-250"}>{_.upperFirst(t("manager.dashboard.notEnoughData"))}</div>
                                            }
                                            {
                                                loadingRadar ?
                                                    <LoadingView/>
                                                    :
                                                    <div style={{textAlign:"center", marginTop:15}}>
                                                        <Row style={{opacity:radarData.enoughValue ? 1 : 0.4}}>
                                                            <SimpleRadarChart isModelCustomEnable={isModelCustomEnable} name1={teamA.length > 1 ? `${teamA.length} `+ _.lowerFirst(t("admin.teams")) :  teamA.length === 1 ? teamA[0].name : ''} name2={teamB.length > 1 ? `${teamB.length} `+ _.lowerFirst(t("admin.teams")) : teamB.length === 1 ?teamB[0].name : ''} data={radarData.tableOfAvg} t={t} isMobile={isMobile}/>
                                                        </Row>
                                                    </div>
                                            }

                                        </div>
                                }
                            </DashboardCard>
                        </Col>
                    }
                    <Col md={isMoreThanHappy ? 6 : 12} sm={12}>
                        <DashboardCard minHeight={600} title={"Focus Matrix"} info={_.upperFirst(t("manager.dashboard.bubbleHelper"))}>
                            {
                                loadingBubbleChart && statOfBubble.length === 0 ?
                                    <LoadingView/>
                                    :
                                    !statOfAvg.total || statOfAvg.total <= 0 ?
                                        <p style={{color:"black", textAlign:"center", fontSize:"1.5em", marginTop:250}}>{noData}</p>
                                        :
                                        <div className={isMoreThanHappy ? "" : "grid grid-cols-2 items-center"}>
                                            <Row className={" mb-5 " + (isMoreThanHappy ? "" : " ml-60")} md={isMoreThanHappy ? 5 : 3} xs={3}>
                                                <div className={"flex flex-wrap justify-around w-full"}>
                                                    {
                                                        categories.length > 5 ?
                                                            <div className={"w-full"}>
                                                                {[0, 1].map(index => (
                                                                    <div className={"w-full flex flex-row justify-around items-center inline"}>
                                                                        {
                                                                            categories.sort((a, b) => a.position - b.position).slice(index === 0 ? 0 : Math.floor(categories.length / 2), index === 0 ? Math.floor(categories.length / 2) : categories.length).map((c, index) => (
                                                                                <div key={index}
                                                                                     className={"focus-matrix-legend mb-10 flex flex-col justify-start items-center w-1/5 h-full " + (bp.isTabletLandscapeOrLess() && "mb-30")}>
                                                                                    <EbloomTooltip text={c.name}>
                                                                                        <div className={"flex flex-col justify-start items-center cursor-pointer w-full"}>
                                                                                            <div className={"w-45 h-45 "}>
                                                                                                {selectBubbleIcon(c.icon,c.color)}
                                                                                            </div>
                                                                                            <p className={"text-center whitespace-nowrap"}>{cutName(c.name)}</p>
                                                                                        </div>
                                                                                    </EbloomTooltip>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            :

                                                            <div className={"flex flex-wrap justify-around w-full"}>
                                                                {
                                                                    categories.sort((a,b) => a.position - b.position).map(c => (
                                                                        <div className={"flex flex-col justify-center items-center w-1/5 mb-15 cursor-pointer"}>
                                                                            <div className={"w-45 h-45 "}>
                                                                                {selectBubbleIcon(c.icon,c.color)}
                                                                            </div>
                                                                            <p className={"text-center"}>{isModelCustomEnable ? cutName(c.name) : _.upperFirst(t("core.category."+c.name))}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </Row>

                                            <SimpleBubbleChart data={statOfBubble}/>

                                        </div>
                            }
                        </DashboardCard>
                    </Col>
                </Row>

                {
                    (teams.length > 2 || filters.length > 0 || Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module)) &&
                    <Row className={"mb-20"}>
                        <Col md={12} sm={12}>
                            <DashboardCard title={_.upperFirst(t("manager.dashboard.scoreTable"))} showInfo={false} >
                                <EbloomHeatmap isModelCustomEnable={isModelCustomEnable} filters={filters} teams={teams} companyToEdit={companyToEdit} startDate={startDate} endDate={endDate} teamToDisplay={teamToDisplay} benchmark={benchmark} company={companyData} commonSurveyActivated={commonSurveyActivated} checkAdvancedUserTeams={checkAdvancedUserTeams}/>
                            </DashboardCard>
                        </Col>
                    </Row>
                }
                <Row className={"mb-20"}>
                    <Col md={12} sm={12}>
                        <DashboardCard title={_.capitalize(t("manager.dashboard.history"))} showInfo={false} showButton={isMoreThanHappy && Login.hasRole("global_admin") && !isMobile} buttonText={loadingExportData ? <LoadingView size={16}/> : <EbloomTooltip text={_.capitalize(t("admin.dataExport"))}><IconButton color={"inherit"} size={"small"}><ExportIcon className={ isProspect ? "text-disabled-grey" : ""} /></IconButton></EbloomTooltip>} buttonVariant={"link"} handleButton={isProspect ? () => setShowModalProspect(true) : handleOpenModal}>
                            <History isModelCustomEnable={isModelCustomEnable} isDashboardManager={true} isMobile={isMobile} isTranslationEnable={isTranslationEnable} showTranslation={showTranslation} teamsSelected={!!teamToDisplay && teamToDisplay.length > 0 && (teamToDisplay[0].id === 0 || teamToDisplay.filter(el => el.id !== 0 ).length > 1) } commonSurveysActivated={commonSurveyActivated} handleIdQuestionModal={handleIdQuestionModal}  {...props}  />
                        </DashboardCard>
                    </Col>
                </Row>
                <Row/>
                <ConfirmModal handleClose={handleCloseModal} show={showModal} title={_.capitalize(t("core.confirm"))+" - " + _.capitalize(t("admin.dataExport"))} showButtons buttonLeftText={_.capitalize(t("core.cancel"))} buttonRightText={_.capitalize(t("core.confirm"))} handleChange={exportData}>
                    <p className={"text-line-break"}>{teamToDisplay &&( (teamToDisplay.length === 1 && teamToDisplay[0].id > 0) || teamToDisplay.length > 1 ) ? _.upperFirst(t("admin.dataExportHelperTeam", {company:teamToDisplay.map(el => el.name).join(", "), period:startDate ? moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") : t("core.fromStart")})) : _.upperFirst(t("admin.dataExportHelperDashboard", {company:companyToEdit ? companyToEdit.name : companyStateName, period:startDate ? moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") : t("core.fromStart")}))}</p>
                    <Row>
                        <EbloomSelectLang handleChange={handleChangeLangDataExport} value={dataExportLang}/>
                    </Row>

                </ConfirmModal>
                <ConfirmModal handleClose={() => {props.handleModalReport(false)}} show={props.showModalReport} title={_.capitalize(t("core.confirm"))+" - " + _.capitalize(t("core.downloadParam", {param:t("manager.dashboard.report.report")}))} showButtons buttonLeftText={_.capitalize(t("core.cancel"))} buttonRightText={_.capitalize(t("core.confirm"))} handleChange={() => {handleReportState(true)}}>
                    <p className={"text-line-break"}>{teamToDisplay &&( (teamToDisplay.length === 1 && teamToDisplay[0].id > 0) || teamToDisplay.length > 1 ) ? _.upperFirst(t("manager.dashboard.report.modalHelper", {company:teamToDisplay.map(el => el.name).join(", "), period:startDate ? moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") : t("core.fromStart")})) : _.upperFirst(t("manager.dashboard.report.modalHelper", {company:companyToEdit ? companyToEdit.name : companyStateName, period:startDate ? moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") : t("core.fromStart")}))}</p>
                    <Row>
                        <EbloomSelectLang handleChange={handleChangeLangReport} value={reportLang}/>
                    </Row>

                </ConfirmModal>
                <ConfirmModal handleClose={handleCloseEvolutionCategoryModal} show={showCategoryEvolutionModal} title={_.capitalize(t("manager.dashboard.categoriesEvolution"))} showButtons={false} size={"lg"} light>
                    <CategoryEvolutionLayout isModelCustomEnable={isModelCustomEnable} companyToEdit={companyToEdit} commonSurveyActivated={commonSurveyActivated}/>
                </ConfirmModal>
                {
                    !companyToEdit &&
                    <Report isModelCustomEnable={isModelCustomEnable} startDate={props.startDate} endDate={props.endDate} timeFilterVersion={timeFilterVersion} shouldDownloadReport={shouldDownloadReport} handleReportState={handleReportState} teamToDisplay={teamToDisplay} lang={reportLang} commonSurveyActivated={commonSurveyActivated}/>
                }
                {
                    Boolean(tagDetail) &&
                    <TagDetail isEbloomModel={isEbloomModel} isModelCustomEnable={isModelCustomEnable} details={tagDetail} setShowDetails={setTagDetail} showDifference={true} difference={tagDetail.previousExerciceDifference}/>
                }
            </div>
            <ProspectModal show={showModalProspect} handleClose={() => setShowModalProspect(false)}/>
            <IdentityQuestionModal show={showModalQuestion} handleClose={() => handleIdQuestionModal(null,false)} id_survey={idSurveyModal} listTeams={!!teamToDisplay && teamToDisplay.length > 0 ? teamToDisplay.map(el => String(el.id)) : []}/>

        </div>
    )

}

ManagerDashboard.defaultProps = {
    companyToEdit: null,
    commonSurveyActivated : false,
    teamToDisplay: null,
    startDate:"1995-12-25",
    endDate:moment().format("YYYY-MM-DD")
};

export default ManagerDashboard;